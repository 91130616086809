.id-collect {
  max-width: 13.866667rem;
  margin: auto;
}

.id-collect .id-collect_tip {
  font-size: 0.48rem;
  padding: 0.64rem 0.533333rem 1.386667rem 0.533333rem;
  background: linear-gradient(270deg, #47b6ff 0%, #227fff 100%);
  color: #ffffff;
  font-size: 0.64rem;
  font-weight: 600;
  line-height: 0.853333rem;
}

.id-collect .van-form {
  margin-top: -0.533333rem;
  background: #ffffff;
  padding-top: 0.533333rem;
  border-radius: 0.32rem 0.32rem 0 0;
}

.id-collect .van-form .van-cell {
  margin-bottom: 0.533333rem;
}

.id-collect .van-form .van-cell .van-cell__value .van-field__body {
  padding-right: 0.933333rem;
}

.id-collect .van-form .van-cell .van-cell__value .van-field__word-limit {
  position: absolute;
  top: 0;
  right: 0;
}

.id-collect .van-form .van-button {
  width: 90%;
  margin: auto;
}